import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import ReviewsIcon from '@mui/icons-material/Reviews';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css'; // Ensure you import the necessary CSS for the ProSidebar
import { Link, useLocation } from 'react-router-dom';
import { tokens } from '../theme';
import ReportIcon from '@mui/icons-material/Report';
const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const location = useLocation();

  // Update the selected state based on the current path
  const [selected, setSelected] = useState(() => {
    const path = location.pathname;
    if (path === '/dashboard') return 'Dashboard';
    if (path === '/user') return 'Manage User';
    if (path === '/category') return 'Category';
    if (path === '/allReviews') return 'Manage Review';
    if (path === '/favourites') return'Favourite Titles';
    if (path === '/reportedreviews') return'Reported Reviews';
    // Default to "Dashboard"
  });

  useEffect(() => {
    const path = location.pathname;
    if (path === '/dashboard') setSelected('Dashboard');
    if (path === '/user') setSelected('Manage User');
    if (path === '/category') setSelected('Category');
    if (path === '/allReviews') setSelected('Manage Review');
    if (path === '/favourites') setSelected('Favourite Titles');
    if (path === '/reportedreviews') setSelected('Reported Reviews');
  }, [location.pathname]);

  const currentUser = useSelector((state) => state.user.currentUser?.data.name);
  const profileimage = useSelector((state) => state.user.currentUser?.data.profile_image);

  return (
    <Box
      sx={{
        "& .pro-menu-item": {
          margin: "5px 0px 20px"
        },
        "& .pro-sidebar-inner": {
          background: `linear-gradient(174.66deg, #282842 4.27%, #3B2146 100.62%)`,
          
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "0px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              margin="0px 0px 0px 30px"
              >
                <Typography  variant="h1" fontFamily="Sharnay Heavy" fontWeight="700" color={colors.grey[100]}>
                  ZIGGLU
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={profileimage}
                  style={{ cursor: "pointer", borderRadius: "50%", objectFit: "cover" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {currentUser}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Actions
            </Typography>
            <Item
              title="Manage User"
              to="/user"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Category"
              to="/category"
              icon={<CategoryIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Manage Review"
              to="/allReviews"
              icon={<ReviewsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Favourite Titles"
              to="/favourites"
              icon={<FavoriteIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Reported Reviews"
              to="/reportedreviews"
              icon={<ReportIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    );
};


export default Sidebar;
