import React, { useCallback, useEffect, useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import Header from '../../components/Header';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';
import { useDispatch, useSelector } from 'react-redux';
import Row from './TableRows';
import { createApiAction } from '../../redux/apiServices';
import axios from 'axios';
import SuccessDialog from '../utils/MessageDialog';

const ReportedReviews = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.api.data);
  const [loading, setLoading] = useState(true);
  const [category,setCategory]=useState();
  const accessToken = localStorage.getItem('access_token');
  const [openDialog, setOpenDialog] = useState(false);
const [dialogMessage, setDialogMessage] = useState('');

const handleDialogClose = () => {
  fetchReviewDetails();
  setOpenDialog(false);
};
  const getCategory=useCallback(async()=>{
    await axios.get('https://vcyrmobapp.elb.cisinlive.com/api/reviews/get_category_list',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`    
          }
          }

    ).then((res)=>{
      setCategory(res.data.data)
    })
  },[])
  const fetchReviewDetails = useCallback(async () => {
    setLoading(true);
    await dispatch(createApiAction("/api/admin/reported_reviews", "GET"));
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    getCategory()
    fetchReviewDetails();
  }, [fetchReviewDetails,getCategory]);

  const handleIgnore = async (row) => {
    try {
      await axios.post('https://vcyrmobapp.elb.cisinlive.com/api/admin/ignore_reported_review', { report_id: row.review.id }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      setDialogMessage('Review ignored successfully');
    } catch (error) {
      setDialogMessage('Failed to ignore review');
    }
    setOpenDialog(true);
  };
  
  const handleDeleteButtonClick = async (row) => {
    try {
      await axios.post('https://vcyrmobapp.elb.cisinlive.com/api/admin/delete_reported_review', { review_id: row.review.id }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });
      setDialogMessage('Review deleted successfully');
    } catch (error) {
      setDialogMessage('Failed to delete review');
    }
    setOpenDialog(true);
  };
  

  // Search functionality
  const searchTag = useSelector((state) => state.api.search);
  const filteredCategories = Array.isArray(data?.data)
    ? data.data.filter((category) =>
        category?.review?.media_title?.toLowerCase().includes(searchTag.toLowerCase())
      )
    : [];

  return (
    <Box
      m="0px 20px 20px 20px"
      sx={{
        cursor: 'default',
        display: 'flex',
        flexDirection: 'column',
        height: '88%',
        overflow: 'auto',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          width: '100%',
          px: 2,
          '@media (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}
      >
        <Box
          sx={{
            mb: 1,
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            '@media (max-width: 600px)': {
              justifyContent: 'center',
              mb: 1,
            },
          }}
        >
          <Header title="Reported Reviews" />
        </Box>
      </Box>
      <TableContainer
        sx={{ margin: '4px', maxWidth: '99%', background: `${colors.primary[400]}`, overflow: 'auto' }}
        component={Paper}
      >
        <Table aria-label="collapsible table" sx={{ cursor: 'default' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '5%' }} />
              <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: '16px', width: '10%' }}>Image</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '16px', width: '15%' }}>Review Title</TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: '16px', width: 'content-fit' }}>Posted By</TableCell>
              <TableCell align="left" sx={{ fontWeight: 'bold', fontSize: '16px', width: '15%' }}>Reported By</TableCell>

              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              filteredCategories.length > 0 ? (
                filteredCategories.map((row) => (
                  <Row key={row.id} row={row} category={category} handleIgnore={handleIgnore} handleDeleteButtonClick={handleDeleteButtonClick} />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No reviews
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* success Dialog */}
      <SuccessDialog 
        open={openDialog} 
        handleClose={handleDialogClose} 
        message={dialogMessage} 
      />
    </Box>
  );
};

export default ReportedReviews;
