import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Autocomplete,
  Grid,
  useMediaQuery,
  Popover,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StarIcon from '@mui/icons-material/Star';
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { createApiAction } from '../../redux/apiServices';
import { useNavigate } from 'react-router-dom';
import { toTitleCase } from '../utils/TitleCase';
import axios from 'axios';
import SuccessDialog from '../utils/MessageDialog';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Header from '../../components/Header';
const Row = ({ row ,handleEditButtonClick,handleDeleteButtonClick}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleReviewDetails = (async (id) => {
    navigate(`/allReviews/${id}`);
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const cellRef = useRef(null);

  const handleArrowClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleScroll = () => {
    if (isMobile && cellRef.current) {
      setAnchorEl(cellRef.current);
    }
  };

  useEffect(() => {
    const table = cellRef.current ? cellRef.current.closest('table') : null;
    if (table) {
      table.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (table) {
        table.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isMobile]);

  const openpop = Boolean(anchorEl);
  const id = openpop ? 'simple-popover' : undefined;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '5%' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ width: '15%', maxHeight: '96px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography sx={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>{toTitleCase(row.media_title)}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ width: '10%', maxHeight: '96px' }}>
          <StarIcon />
          <Typography>{row.rating}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ width: '15%', maxHeight: '96px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography sx={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>{row.review_short}</Typography>
        </TableCell>
        <TableCell align="center" maxWidth="200px">
            <img src={row.media_url} alt={row.media_title} style={{ maxWidth: '100%', height: '100px',objectFit:'contain' }} />
        </TableCell>
        <TableCell align="center" sx={{ width: '10%', maxHeight: '96px' }} ref={cellRef}>
      <Box display="flex" gap={2} alignItems="center" height="100%" justifyContent="center" sx={{flex:'1 1 auto'}}>
        {isMobile ? (
          <>
            <IconButton size="small" onClick={handleArrowClick}>
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
            <Popover
              id={id}
              open={openpop}
              anchorEl={anchorEl}
              onClose={handleArrowClick}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',  
                horizontal: 'center',
              }}
              
            >
              <Box display="flex" flexDirection="column" alignItems="center" gap={1} p={1} 
              sx={{bgcolor:'#6b5d8f'}}>
                <Button variant="contained" color="secondary" size="small" fullWidth startIcon={<EditIcon/>} onClick={() => handleEditButtonClick(row)}>
                  Edit
                </Button>
                <Button variant="contained" color="secondary" size="small" fullWidth startIcon={<InfoIcon />} onClick={() => handleReviewDetails(row.id)}>
                  Info
                </Button>
                <Button variant="contained" color="secondary" size="small" fullWidth startIcon={<DeleteIcon />} onClick={() => handleDeleteButtonClick(row)}>
                  Delete
                </Button>
              </Box>
            </Popover>
          </>
        ) : (
          <>
            <Button variant="contained" color="primary" size="small" startIcon={<EditIcon/>} onClick={() => handleEditButtonClick(row)}>
              Edit
            </Button>
            <Button variant="contained" color="secondary" size="small" startIcon={<InfoIcon />} onClick={() => handleReviewDetails(row.id)}>
              Info
            </Button>
            <Button variant="contained" color="secondary" size="small" startIcon={<DeleteIcon />} onClick={() => handleDeleteButtonClick(row)}>
              Delete
            </Button>
          </>
        )}
      </Box>
    </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding:'4px',margin: 1 , backgroundColor:'#6b5d8f',borderRadius:'10px'
            }}>
              <Typography variant="h6" gutterBottom component="div">
                User Details
              </Typography>
              <Table size="small" aria-label="user details">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Profile Image</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Username</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row.user?.id}</TableCell>
                    <TableCell>
                      <Avatar alt={row.user?.name} src={row.user?.profile_image} />
                    </TableCell>
                    <TableCell>{row.user?.name}</TableCell>
                    <TableCell>{row.user?.email}</TableCell>
                    <TableCell>{row.user?.username}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
//!!WARNING!!this is not a clean code

  const ReviewsManagement = () => {
    const navigate=useNavigate()
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const data = useSelector((state) => state.api.data);
    const errorData=useSelector((state)=>state.api.errordata)
    const MenuProps = {
      PaperProps: {
        sx: {
          backgroundColor: `${colors.primary[700]}`,
          '& .MuiMenuItem-root': {
            backgroundColor: `${colors.primary[700]}`,
            '&:hover': {
              backgroundColor: `${colors.primary[600]}`,
            },
          },
        },
      },
    };
    const [loading, setLoading] = useState(true);
    const [apiLoading,setApiLoading]=useState(false);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false); // For edit dialog
    const [editReview, setEditReview] = useState({
      id: '',
      media_url: '',
      media_title: '',
      enjoyed: '',
      rating: '',
      review_short: '',
      review_full: '',
      note: '',
      category:'',
      media_id:''
    });
    const [newReview, setNewReview] = useState({
      media_url: '',
      media_title: '',
      enjoyed: '',
      rating: '',
      review_short: '',
      review_full: '',
      note: '',
      category:'',
      media_id:''
    });
    const [imageFile, setImageFile] = useState(null);
    const [errors, setErrors] = useState({});
    const [enjoyedOrUpnext, setEnjoyedOrUpnext] = useState([]);
    const [categories, setCategories] = useState([]);
    const [mediaList, setMediaList] = useState([]);
    const [showUploadFields, setShowUploadFields] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const accessToken = localStorage.getItem('access_token');
    const [deleteOpen, setDeleteOpen]=useState(false);
    const [deleteId, setDeleteId]=useState('');
    const getReviews = useCallback(async () => {
      try{
        await dispatch(createApiAction('/api/admin/get_reviews_list', 'GET'));
      setLoading(false);
      }catch(err){
        if(errorData.code===403)
          navigate("/")
      }
    }, [dispatch]);
    
    const handleCategory = async (event) => {
      const categoryId = event.target.value;
      setNewReview({ ...newReview, category: categoryId });
      try {
        const response = await axios.post(
          'https://vcyrmobapp.elb.cisinlive.com/api/reviews/get_media_list_by_category',
          { category_id: categoryId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`  
            }
          }
        );
        if (response.data.success) {
          setMediaList(response.data.data);
        } else {
          setMediaList([]);
        }
      } catch (error) {
        console.error('Error fetching media list:', error);
      }
    };
    
    const handleMediaSelect = (event, newValue) => {
      if (newValue && newValue.media_title === 'Upload Custom Media') {
        setShowUploadFields(true);
        setNewReview({ ...newReview, media_id: '', media_title: ''}); // clear media_id
      } else {
        setShowUploadFields(false);
        if (newValue) {
          setNewReview({ ...newReview, media_id: newValue.id, media_title: ''});
        }
      }
      setErrors((prev) => ({ ...prev, media_id: '' }));
    };

    useEffect(() => {
      setLoading(true)
      getReviews();
      const fetchData = async () => {
        try {
          const response = await axios.get('https://vcyrmobapp.elb.cisinlive.com/api/common/dropdownlist',
            {
              headers: {
              Authorization: `Bearer ${accessToken}`  
            }
          }
          );
          setEnjoyedOrUpnext(response.data.data.enjoyedOrUpnext);
          setCategories(response.data.data.categories);
          setLoading(false)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      getReviews();
      fetchData();
    }, [getReviews]);

    const handleImageUpload = (e) => {
      const file = e.target.files[0];
      const fileUrl = URL.createObjectURL(file);
      setImageFile(file);
      setNewReview({...newReview,media_url:fileUrl});
    };
    const validateReview = async () => {
      const newErrors = {};
      if (!newReview.media_id && !newReview.media_url) {
        newErrors.media_id = "Please select a media or upload custom media";
      } else if (newReview.media_id === '' && (!newReview.media_title)) {
        if (!newReview.media_title) newErrors.media_title = 'Media title is required';
      }
  
      if (!newReview.enjoyed) newErrors.enjoyed = 'Enjoyed field is required';
      if (!newReview.category) newErrors.category = "Category is Required";
      if (newReview.enjoyed === 1) {
        if (!newReview.rating || newReview.rating < 4 || newReview.rating > 13) {
          newErrors.rating = 'Rating must be between 4 and 13';
        }
        if (!newReview.review_short || newReview.review_short.split(' ').length > 20) {
          newErrors.review_short = 'Short review must be less than 20 words';
        }
        if (!newReview.review_full) {
          newErrors.review_full = 'Full review is required';
        }
      } else if (newReview.enjoyed === 2) {
        if (!newReview.note) {
          newErrors.note = 'Note is required';
        }
      }
  
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        return true;
      }
      return false;
    };
  
    const handleAddReview = async () => {
      if (!(await validateReview())) return;
      setApiLoading(true);
      const formData = new FormData();
      formData.append('category_id', newReview.category);
      if (imageFile) {
        formData.append('mediaFile', imageFile);
        formData.append('media_title', newReview.media_title);
      } else {
        formData.append('media_id', newReview.media_id);
      }
      if (newReview.enjoyed === 1) {
        formData.append('enjoyed', true);
        formData.append('rating', newReview.rating);
        formData.append('review_short', newReview.review_short);
        formData.append('review_full', newReview.review_full);
      } else {
        formData.append('enjoyed', false);
        formData.append('note', newReview.note);
      }
      formData.append('list_ids',null)
      console.log([...formData]);
      try {
        await dispatch(createApiAction('/api/reviews/post_review', 'POST', formData));
        await getReviews();  
        setOpen(false);
          setDialogMessage("Review Added Successfully");
          setDialogOpen(true);  
          setNewReview({
            media_title: '',
            enjoyed: '',
            rating: '',
            review_short: '',
            review_full: '',
            note: '',
            category: '',
            media_id: '',
          });
          setImageFile(null);
          setErrors({});
      } catch (err) {
        console.error(err);
      } finally {
        setApiLoading(false);
      }
    };
    const handleMediaChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const fileUrl = URL.createObjectURL(file);
        setImageFile(file)
        setEditReview({ ...editReview, media_url: fileUrl })
      }
    };
    const validateEditReview=async()=>{
      const newErrors = {};
      console.log(editReview)
      if (!editReview.media_id && !editReview.media_url) {
        newErrors.media_id = "Please select a media or upload custom media";
      } else if (editReview.media_id === '' && (!editReview.media_title)) {
        if (!editReview.media_title) newErrors.media_title = 'Media title is required';
      }
      // if (!editReview.enjoyed) newErrors.enjoyed = 'Enjoyed field is required';
      if(!editReview.category)newErrors.category="Category is Required"
      if (editReview.enjoyed === 1) {
        if (!editReview.rating || editReview.rating < 4 || editReview.rating > 13) {
          newErrors.rating = 'Rating must be between 4 and 13';
        }
        if (!editReview.review_short || editReview.review_short.split(' ').length > 20) {
          newErrors.review_short = 'Short review must be less than 20 words';
        }
        if (!editReview.review_full) {
          newErrors.review_full = 'Full review is required';
        }
      } else if (editReview.enjoyed === 2) {
        if (!editReview.note) {
          newErrors.note = 'Note is required';
        }
      }

      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        return true;
      }
      return false;
    }
    const handleEditReview = async () => {
      if (!(await validateEditReview())) return;
      setApiLoading(true);
      console.log(editReview)
        const formData = new FormData();
        formData.append('review_id', editReview.id);
        formData.append('category_id',editReview.category)
        if (imageFile) {
          formData.append('mediaFile', imageFile);
          formData.append('media_title', editReview.media_title);
        }else{
          formData.append('media_id', editReview.media_id);
        }
        if(editReview.enjoyed===1){
          formData.append('enjoyed',true)
          formData.append('rating', editReview.rating);
          formData.append('review_short', editReview.review_short);
          formData.append('review_full', editReview.review_full);
        }else{
          formData.append('enjoyed',false)
          formData.append('note', editReview.note);
        }
        console.log([...formData]); // for debugging
        try {  
            dispatch(createApiAction('/api/admin/edit_review','POST',formData))
            await getReviews();
            setOpenEdit(false);
            setEditReview({
              id: '',
              media_title: '',
              enjoyed: '',
              rating: '',
              review_short: '',
              review_full: '',
              note: '',
              category:''
            });
            setImageFile(null);
            setErrors({});
        } catch (error) {
          console.log(error)
        }finally{
          setApiLoading(false)
        }
      
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setOpenEdit(false);
      setErrors({});
      setNewReview({
        media_url: '',
        media_title: '',
        enjoyed: '',
        rating: '',
        review_short: '',
        review_full: '',
        note: '',
        category:'',
        media_id:''
      });
      setEditReview({
        id: '',
        media_url: '',
        media_title: '',
        enjoyed: '',
        rating: '',
        review_short: '',
        review_full: '',
        note: '',
        category:'',
        media_id:''
      });
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const handleEditButtonClick = (review) => {
      console.log(review)
      setEditReview({
        id: review.id,
        media_url: review.media_url,
        media_title: review.media_title,
        enjoyed: review.enjoyed,
        rating: review.rating,
        review_short: review.review_short,
        review_full: review.review_full,
        note: review.note,
        category: review.review_category_id,
        media_id: review.media_id,
      });
      setOpenEdit(true);
    };
    const handleDeleteButton=(row)=>{
      setDeleteId(row.id)
      setDeleteOpen(true)
    }
    const handleDeleteButtonClick=async()=>{
      setApiLoading(true)
      console.log(deleteId)
      try {
        dispatch(createApiAction('/api/admin/delete_review','POST',{
          "review_id":deleteId
        })).then(()=>{
          if(data.success===true){
            getReviews(); 
            setOpen(false);
            setEditReview({
              media_title: '',
              enjoyed: '',
              rating: '',
              review_short: '',
              review_full: '',
              note: '',
            });
            setImageFile(null);
            setErrors({});
            setDialogMessage("Review Deleted Successfully")
            setDialogOpen(true)
            setDeleteOpen(false)
          }
        })
      } catch (error) {
        console.log(error)
      }finally{
        setApiLoading(false)
      }

    }



 //search functionality
  const searchtag=useSelector((state)=>state.api.search)
  const filteredCategories = Array.isArray(data?.data)
  ? data.data.filter(category =>
      category?.media_title?.toLowerCase().includes(searchtag.toLowerCase())
    )
  : [];
    if (loading) {
      return (
        <Box
          m="16px"
          sx={{
            cursor: 'default',
            display: 'flex',
            flexDirection: 'column',
            height: '90vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <Box
        m="16px"
        sx={{
          cursor: 'default',
          display: 'flex',
          flexDirection:'column',
          height: '88%',
          overflow: 'auto',
          
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
            px: 2, // Padding for responsiveness
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              alignItems: 'center',
            },
          }}
        >
          <Box
            sx={{
              mb:1,
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              '@media (max-width: 600px)': {
                justifyContent: 'center',
                mb: 1, // Margin bottom for spacing in smaller screens
              },
            }}
          >
            <Header
                title="Managing Reviews"
              />
          </Box>
          {/* <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={() => handleClickOpen()}
            sx={{
              '@media (max-width: 600px)': {
                width: '100%',
              },
            }}
          >
            Add Review
          </Button> */}
        </Box>
        <TableContainer
          sx={{ margin: '4px',maxWidth:'99%', background: `${colors.primary[400]}`,overflow:'auto'}}
          component={Paper}
        >
          <Table aria-label="collapsible table" sx={{ cursor: 'default' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '5%' }} />
                <TableCell sx={{ fontWeight: 'bold', fontSize: '16px', width: '15%' }}>Review Title</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', width: '10%' }}>Ratings</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', width: '15%' }}>Review</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: '16px', width: '25%' }}>Image</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCategories.length > 0 ? (
                filteredCategories.map((row) => (
                  <Row key={row.id} row={row}  handleEditButtonClick={handleEditButtonClick} handleDeleteButtonClick={handleDeleteButton}/>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No reviews
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Add review */}
        <Dialog
          sx={{
            '& .MuiDialog-paper': {
              backgroundColor: `${colors.grey[500]}`,
              borderRadius: 4,
              padding: 2,
            },
            '& .MuiDialogTitle-root': {
              fontSize: 20,
              color: `${colors.primary[700]}`,
              fontWeight: 'bold',
            },
            '& .MuiInput-root::after': {
              borderBottom: `px solid ${colors.primary[700]}`,
            },
            '& .MuiInput-root::before': {
              borderBottom: `1px solid #262429`,
            },
            '& .MuiInput-root:hover::before': {
              borderBottom: `1px solid ${colors.primary[500]} `,
            },
            '& .MuiInput-root:hover::after': {
              borderBottom: `1px solid ${colors.primary[500]}`,
            },
            '& .MuiDialogContentText-root': {
              color: `${colors.primary[700]}`,
            },
            '& .MuiInputLabel-root': {
              color: `${colors.primary[700]}`,
            },
            '& .Mui-focused': {
              fontWeight:"bold",
              color: `${colors.primary[700]}`,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: `${colors.primary[700]}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: `${colors.primary[700]}`,  // Default outline border color
            },
            '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
            border:`2px solid ${colors.primary[700]}` ,  // Change outline border color on hover
            },
            '& .MuiTextField-root:hover .MuiOutlinedInput-notchedOutline':{
              borderBottom:`2px solid`,
              borderColor: `${colors.primary[700]}`,  // Change outline border color
            },
            '& .MuiInput-root::after:hover' :{
              borderBottom: `2  px solid ${colors.primary[700]}`,
            }
          }}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Add Review</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To add a review, please fill in the form below.
            </DialogContentText>
            {/* select Category */}
            <FormControl
              fullWidth
              margin="dense"
              onClick={()=>{setErrors(prevstate=>({...prevstate,category:''}))}}
              error={!!errors.category}
              sx={{
                paddingBottom:'8px',
                '& .MuiInputLabel-root': { color: `${colors.primary[700]}` },
                '& .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.primary[700]}` },
                '& .MuiSelect-root': {
                  '&:hover': { backgroundColor: `${colors.primary[500]}` },
                },
                '& .MuiSelect-icon': { fill: `${colors.primary[700]}` },
              }}
            >
              <InputLabel>Category</InputLabel>   
              <Select
                value={newReview.category}
                onClick={()=>{setErrors(prevstate=>({...prevstate,category:''}))}}
                onChange={handleCategory}
                label="Category"
                MenuProps={MenuProps}
                sx={{ '& .MuiInputBase-input': { color: colors.primary[900] } }}
              >
                {categories.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
                
              </Select>
              {errors.category && <FormHelperText>{errors.category}</FormHelperText>}
            </FormControl>
            {/* select Media */}
            <Box sx={{ width: '100%', maxHeight: '50%', margin: '0 auto'}}>
              <Autocomplete
                PaperComponent={(props) => (
                  <Paper
                    {...props}
                    sx={{
                      backgroundColor: `${colors.primary[700]}`,
                      '& .MuiMenuItem-root': {
                        backgroundColor: `${colors.primary[700]}`,
                        '&:hover': {
                          backgroundColor: `${colors.primary[600]}`,
                        },
                        '&:hover::after':`${colors.primary[700]}`,
                      },
                    }}
                  />
                )}
                sx={{
                  '& .MuiInputBase-input': {
                    color: colors.primary[900],
                  },
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.primary[700]}` },
                  '& .MuiAutocomplete-root': {
                    '& .MuiOutlinedInput-root': {
                      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.primary[500]}` },
                    },
                  },
                  '& .MuiSvgIcon-root': {
                    color: colors.primary[700],
                  },
                }}
                options={[...mediaList, { media_title: 'Upload Custom Media', media_url: '' }]}
                getOptionLabel={(option) => option.media_title}
                onChange={handleMediaSelect}
                renderOption={(props, option) => (
                  <Box component="li" {...props} 
                    sx={{ backgroundColor: `${colors.primary[700]}`, display: 'flex', alignItems: 'center' }}>
                    {option.media_url && (
                      <Box
                        component="img"
                        src={option.media_url}
                        alt={option.media_title}
                        sx={{ width: '50px', height: '50px', marginRight: '10px' }}
                      />
                    )}
                    <Typography variant="body1">{option.media_title}</Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Media"
                    variant="outlined"
                    fullWidth
                    error={!!errors.media_id}
                    helperText={errors.media_id}
                    onClick={()=>{
                      setErrors(prevstate=>({...prevstate,media_id:''}))
                    }}
                  />
              )}  
              />
              {showUploadFields && (
                <>
                  {/* <TextField
                    autoFocus
                    margin="dense"
                    id="media_url"
                    label="Upload Image"
                    type="file"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: 'image/*' }}
                    onChange={handleImageUpload}
                    error={!!errors.media_url}
                    helperText={errors.media_url}
                    sx={{ '& .MuiInputBase-input': { color: 'primary.main' } }}
                    autoComplete='off'
                  /> */}
                  <TextField
                  label="Media Title"
                  fullWidth
                  margin="normal"
                  value={newReview.media_title}
                  onChange={(e) =>
                    setNewReview({ ...newReview, media_title: e.target.value })
                  }
                  onClick={()=>{
                    setErrors(prevstate=>({...prevstate,media_title:''}))
                  }}
                  error={Boolean(errors.media_title)}
                  helperText={errors.media_title}
                />
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginTop: '1rem'
                  
                }}>
                {newReview.media_url && <img src={newReview.media_url} alt={newReview.media_title} style={{ padding:'8px',maxWidth: '50%' }} />}
                <input id="file" type="file" onChange={handleImageUpload} style={{
                        opacity: 0,
                        width: '0.1px',
                        height: '0.1px',
                        position: 'absolute'
                    }} />
                    <label for="file" style={{
                        display: 'inline-block',
                        padding: '10px 20px',
                        borderRadius: '10px',
                        background: `linear-gradient(54.66deg, #282842 4.27%, #3B2146 100.62%)`,
                        color: '#fff',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                    }}>Select file</label>
                </Box>

                  {/* <TextField
                    margin="dense"
                    id="media_title"
                    label="Media Title"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={newReview.media_title}
                    onChange={(e) => setNewReview({ ...newReview, media_title: e.target.value })}
                    error={!!errors.media_title}
                    helperText={errors.media_title}
                    sx={{ '& .MuiInputBase-input': { color: 'primary.main' } }}
                    autoComplete='off'
                  /> */}
                </>
              )}
            </Box>
            {/* EnjoyedOrNot */}
            <FormControl fullWidth margin="dense" error={!!errors.enjoyed} sx={{
              paddingBottom:'4px',
              '& .MuiInputLabel-root': {
                color: `${colors.primary[700]}`, 
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `${colors.primary[700]}`, 
              },
              '& .MuiSelect-root': {
                '&:hover': {
                  backgroundColor: `${colors.primary[500]}`,  
                },
              },
              
              '& .MuiSelect-icon ':{
                fill:`${colors.primary[700]}` 
              }
            }}>
              <InputLabel>Enjoyed</InputLabel>
              <Select
                value={newReview.enjoyed}
                MenuProps={MenuProps}
                onClick={()=>{setErrors(prevstate=>({...prevstate,enjoyed:''}))}}
                onChange={(e) => setNewReview({ ...newReview, enjoyed: e.target.value })}
                label="Enjoyed"
                sx={{
                  '& .MuiInputBase-input': {
                    color: colors.primary[900],
                  },
                }}
              >
                {enjoyedOrUpnext.map((item)=>(
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
              {errors.enjoyed && <FormHelperText>{errors.enjoyed}</FormHelperText>}
            </FormControl>
            {newReview.enjoyed ===1 && (
              <>
                <TextField
                  margin="dense"
                  id="rating"
                  label="Rating"
                  type="number"
                  fullWidth
                  value={newReview.rating}
                  onChange={(e) => setNewReview({ ...newReview, rating: e.target.value })}
                  error={!!errors.rating}
                  helperText={errors.rating}
                  sx={{
                    '& .MuiInputBase-input': {
                      color: colors.primary[900],
                    },
                  }}
                  autoComplete='off'
                />
                <TextField
                  margin="dense"
                  id="review_short"
                  label="Short Review"
                  type="text"
                  fullWidth
                  
                  value={newReview.review_short}
                  onChange={(e) => setNewReview({ ...newReview, review_short: e.target.value })}
                  error={!!errors.review_short}
                  helperText={errors.review_short}
                  sx={{
                    '& .MuiInputBase-input': {
                      color: colors.primary[900],
                    },
                  }}
                  autoComplete='off'
                />
                <TextField
                  margin="dense"
                  id="review_full"
                  label="Full Review"
                  type="text"
                  fullWidth
                  value={newReview.review_full}
                  onChange={(e) => setNewReview({ ...newReview, review_full: e.target.value })}
                  error={!!errors.review_full}
                  helperText={errors.review_full}
                  sx={{
                    '& .MuiInputBase-input': {
                      color: colors.primary[900],
                    },
                  }}
                  autoComplete='off'
                />
              </>
            )}
            {newReview.enjoyed === 2 && (
              <TextField
                margin="dense"
                id="note"
                label="Note"
                type="text"
                fullWidth
                value={newReview.note}
                onChange={(e) => setNewReview({ ...newReview, note: e.target.value })}
                error={!!errors.note}
                helperText={errors.note}
                sx={{
                  '& .MuiInputBase-input': {
                    color: colors.primary[900],
                  },
                }}
                autoComplete='off'
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained"
            color="primary" onClick={() => {
              setOpen(false);
              setNewReview({
                media_url: '',
                media_title: '',
                enjoyed: '',
                rating: '',
                review_short: '',
                review_full: '',
                note: '',
              });
              setErrors({});
              setImageFile(null);
            }}>Cancel</Button>
            <Button  variant="contained"
            color="secondary" onClick={handleAddReview}>
            {apiLoading ? <CircularProgress size={24} />:'Add Review' }</Button>
          </DialogActions>
        </Dialog>
        {/* edit Review */}
        <Dialog 
          sx={{
            '& .MuiDialog-paper': {
              backgroundColor: `${colors.grey[500]}`,
              borderRadius: 4,
              padding: 2,
            },
            '& .MuiDialogTitle-root': {
              fontSize: 20,
              color: `${colors.primary[700]}`,
              fontWeight: 'bold',
            },
            '& .MuiInput-root::after': {
              borderBottom: `2px solid ${colors.primary[700]}`,
            },
            '& .MuiInput-root::before': {
              borderBottom: `1px solid #262429`,
            },
            '& .MuiInput-root:hover::before': {
              borderBottom: `1px solid ${colors.primary[500]} `,
            },
            '& .MuiInput-root:hover::after': {
              borderBottom: `1px solid ${colors.primary[500]}`,
            },
            '& .MuiDialogContentText-root': {
              color: `${colors.primary[700]}`,
            },
            '& .MuiInputLabel-root': {
              color: `${colors.primary[700]}`,
            },
            '& .Mui-focused': {
              fontWeight:"bold",
              color: `${colors.primary[700]}`,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: `${colors.primary[700]}`,
            },
            '& .MuiOutlinedInput-root': {
              color:`${colors.primary[700]}`,
                '& fieldset': {
                  borderColor: `${colors.primary[700]}`,
                  },
                '&:hover fieldset': {
                  borderColor: `${colors.primary[500]}`,
                  },
                '&.Mui-focused fieldset': {
                  borderColor: `${colors.primary[500]}`,
                  },
                '&.Mui-error fieldset': {
                  borderColor: `${colors.primary[500]}`,
                  },
              }
          }}
          open={openEdit} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>Edit Review</DialogTitle>
          <DialogContent>
            <Box>
              <Grid container spacing={2}>
                <Box sx={{ flex: '1 1 50%', padding: '16px' }}>
                  {editReview.media_url && <img src={editReview.media_url} alt={editReview.media_title} style={{ maxWidth: '50%' }} />}
                  <div style={{padding:'4px'}}>
                    <input id="file" type="file" onChange={handleMediaChange} style={{
                        opacity: 0,
                        width: '0.1px',
                        height: '0.1px',
                        position: 'absolute'
                    }} />
                    <label for="file" style={{
                        display: 'inline-block',
                        padding: '10px 20px',
                        borderRadius: '10px',
                        background: `linear-gradient(54.66deg, #282842 4.27%, #3B2146 100.62%)`,
                        color: '#fff',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                    }}>Select file</label>
                  </div>
                </Box>
              </Grid>
            </Box>
            <Box sx={{ padding: '8px', color:`${colors.primary[700]}`}}>
            <TextField
                fullWidth 
                autoComplete='off'
                sx={{marginBottom:1.5}}
                label="Media Title"
                value={editReview.media_title}
                onChange={(e) => setEditReview({...editReview, media_title: e.target.value})}
              />
              <FormControl
                fullWidth
                margin="dense"
                onClick={()=>{setErrors(prevstate=>({...prevstate,category:''}))}}
                error={!!errors.category}
                sx={{
                  paddingBottom:'8px',
                  '& .MuiInputLabel-root': { color: `${colors.primary[700]}` },
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: `${colors.primary[700]}` },
                  '& .MuiSelect-root': {
                    '&:hover': { backgroundColor: `${colors.primary[500]}` },
                  },
                  '& .MuiSelect-icon': { fill: `${colors.primary[700]}` },
                }}
              >
                <InputLabel>Category</InputLabel>   
                <Select
                  value={editReview.category}
                  onClick={()=>{setErrors(prevstate=>({...prevstate,category:''}))}}
                  onChange={(e) => setEditReview({...editReview, category: e.target.value})}
                  label="Category"
                  MenuProps={MenuProps}
                  sx={{ '& .MuiInputBase-input': { color: colors.primary[900] } }}
                >
                  {categories.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem> 
                  ))}
                  
                </Select>
                {errors.category && <FormHelperText>{errors.category}</FormHelperText>}
              </FormControl>
              <FormControl fullWidth margin="dense" error={!!errors.enjoyed} 
              sx={{
                paddingBottom:'4px',
                '& .MuiInputLabel-root': {
                  color: `${colors.primary[700]}`, 
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${colors.primary[700]}`, 
                },
                '& .MuiSelect-root': {
                  '&:hover': {
                    backgroundColor: `${colors.primary[500]}`,  
                  },
                },
                
                '& .MuiSelect-icon ':{
                  fill:`${colors.primary[700]}` 
                }
              }}>
              <InputLabel>Enjoyed</InputLabel>
              <Select
                value={
                  editReview
                    ? editReview.enjoyed 
                      ? (enjoyedOrUpnext.find(item => item.id === 1) || {}).id || ''
                      : (enjoyedOrUpnext.find(item => item.id === 2) || {}).id || ''
                    : ''
                }
                MenuProps={MenuProps}
                onClick={()=>{setErrors(prevstate=>({...prevstate,enjoyed:''}))}}
                onChange={(e) => setEditReview({ ...editReview, enjoyed: e.target.value })}
                label="Enjoyed"
                sx={{
                  '& .MuiInputBase-input': {
                    color: colors.primary[900],
                  },
                }}
              >
                {enjoyedOrUpnext.map((item)=>(
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
              {errors.enjoyed && <FormHelperText>{errors.enjoyed}</FormHelperText>}
              </FormControl>
              {(editReview.enjoyed === 1||editReview.enjoyed === true ) && (
                <>
                  <TextField
                    margin="dense"
                    id="rating"
                    label="Rating"
                    type="number"
                    fullWidth
                    value={editReview.rating}
                    onChange={(e) => setEditReview({ ...editReview, rating: e.target.value })}
                    error={!!errors.rating}
                    helperText={errors.rating}
                    sx={{
                      '& .MuiInputBase-input': {
                        color: colors.primary[900],
                      },
                    }}
                    autoComplete='off'
                  />
                  <TextField
                    margin="dense"
                    id="review_short"
                    label="Short Review"
                    type="text"
                    fullWidth
                    value={editReview.review_short}
                    onChange={(e) => setEditReview({ ...editReview, review_short: e.target.value })}
                    error={!!errors.review_short}
                    helperText={errors.review_short}
                    sx={{
                      '& .MuiInputBase-input': {
                        color: colors.primary[900],
                      },
                    }}
                    autoComplete='off'
                  />
                  <TextField
                    margin="dense"
                    id="review_full"
                    label="Full Review"
                    type="text"
                    fullWidth
                    value={editReview.review_full}
                    onChange={(e) => setEditReview({ ...editReview, review_full: e.target.value })}
                    error={!!errors.review_full}
                    helperText={errors.review_full}
                    sx={{
                      '& .MuiInputBase-input': {
                        color: colors.primary[900],
                      },
                    }}
                    autoComplete='off'
                  />
                </>
              )}
              {(editReview.enjoyed === 2||editReview.enjoyed === false )&& (
                <TextField
                  margin="dense"
                  id="note"
                  label="Note"
                  type="text"
                  fullWidth
                  value={editReview.note}
                  onChange={(e) => setEditReview({ ...editReview, note: e.target.value })}
                  error={!!errors.note}
                  helperText={errors.note}
                  sx={{
                    '& .MuiInputBase-input': {
                      color: colors.primary[900],
                    },
                  }}
                  autoComplete='off'
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditReview} color="primary">
          {apiLoading ? <CircularProgress size={24} />:'Edit   Review' }
          </Button>
        </DialogActions>
      </Dialog> 
      {/* display popupMessages */}
      <SuccessDialog 
        open={dialogOpen} 
        handleClose={handleDialogClose} 
        message={dialogMessage} 
      />
      {/* Dialog for deleting Review */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: colors.primary[600],
          },
        }}
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteOpen(false)}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            onClick={handleDeleteButtonClick}
            variant="contained"
            color="secondary"
          >
            {apiLoading ? <CircularProgress size={24} /> : 'Delete Category'}
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
    );
  };

export default ReviewsManagement;
