import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  styled,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { createApiAction } from "../../redux/apiServices";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(
    theme.palette.mode
  );
  const dispatch=useDispatch();
  const navigate=useNavigate(); 
  const statisticsCards=useSelector((state)=>state.api.data)

  useEffect(()=>{
    dispatch(createApiAction("/api/admin/statistics_cards","GET"))
  },[dispatch])

  const data = {
    reviewCounts: statisticsCards.data?.reviewCounts,
    userCounts: statisticsCards.data?.userCounts,
    reportedReviewCounts: statisticsCards.data?.reportedReviewCounts,
  };

  // Custom styled Card component
  const CustomCard = styled(Card)(
    ({ theme }) => ({
      boxShadow: theme.shadows[4],
      transition:
        "transform 0.3s ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
      },
      "& .MuiCard-root": {
        border: "none",
      },
      "& .MuiCardContent-root": {
        backgroundColor:
          colors.primary[400],
        "& .MuiTypography-h4": {
          color: colors.primary[100],
        },
        "& .MuiTypography-body2": {
          color:
            colors.greenAccent[300],
        },
      },
    })
  );

  return (
    <Box sx={{ padding: "10px 40px 40px 40px" }}>
      <Box
        sx={{ marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
        }}

      >
        <Header
          title="Zigglu Admin Dashboard"
        />
      </Box>
      <Grid
        container
        spacing={4}
        justifyContent="center"
      >
        <Grid item xs={12} md={4}>
          <CustomCard>
            <CardContent>
              <Typography
                variant="h4"
                component="div"
              >
                Review Counts
              </Typography>
              <Typography variant="body2">
                {data.reviewCounts}
              </Typography>
            </CardContent>
          </CustomCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomCard>
            <CardContent>
              <Typography
                variant="h4"
                component="div"
              >
                User Counts
              </Typography>
              <Typography variant="body2">
                {data.userCounts}
              </Typography>
            </CardContent>
          </CustomCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomCard>
            <CardContent>
              <Typography
                variant="h4"
                component="div"
              >
                Reported Review Counts
              </Typography>
              <Typography variant="body2">
                {
                  data.reportedReviewCounts
                }
              </Typography>
            </CardContent>
          </CustomCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
