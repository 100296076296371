import React, { useState } from "react";
import Topbar from "./components/Topbar";
import { ColorModeContext, useMode } from "./theme";
import Login from "./pages/auth/Login";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Sidebar from "./components/Sidebar";
import { Navigate, Route, Routes  } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoutes"; 
import { useSelector } from "react-redux";
import Dashboard from "./pages/dashboard/Dashboard";
import Category from "./pages/category/Category";
import UserDetail from "./pages/user/UserDetail";
import UserReview from "./pages/user/UserReviews";
import User from "./pages/user/User";
import ReviewsManagement from "./pages/reviewmanagement/ReviewsManagement";
import ReviewDetails from "./pages/reviewmanagement/ReviewDetails";
import Favourites from "./pages/favourites/Favourites";
import ResetPassword from "./pages/auth/ResetPassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Profile from "./pages/profile/Profile";
import ReportedReviews from "./pages/reportedreviews/ReportedReviews";
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(false);
  const isAuthenticated=useSelector((state)=>state.user.currentUser)

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isAuthenticated && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {isAuthenticated && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              {!isAuthenticated && <Route path="/" element={<Login />} />}
              <Route path="/forgot-password" element={<ForgotPassword />}/>
              <Route path="/reset-password" element={<ResetPassword />}/>
              <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
              <Route path="/user" element={<ProtectedRoute element={<User />} />} />
              <Route path="/category" element={<ProtectedRoute element={<Category />} />} />
              <Route path="/user/:slug" element={<ProtectedRoute element={<UserDetail />} />} />
              <Route path="/user/:slug/review/:slug" element={<ProtectedRoute element={< UserReview/>} />} />
              <Route path="/allReviews" element={<ProtectedRoute element={<ReviewsManagement />} />} />
              <Route path="/allReviews/:slug"element={<ProtectedRoute element={<ReviewDetails/>}/>}/>
              <Route path="/favourites" element={<ProtectedRoute element={<Favourites/>}/>}/>
              <Route path="/profile" element={<ProtectedRoute element={<Profile/>}/>}/>
              <Route path="*" element={<ProtectedRoute element={<Dashboard/>}/>} />
              <Route path="/reportedreviews" element={<ProtectedRoute element={<ReportedReviews/>}/>}/>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
