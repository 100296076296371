import axios from 'axios';
import { fetchSuccess, fetchError} from './apislice';
import { logout } from './userslice';

const getToken = () => localStorage.getItem('access_token');
const getRefreshToken = () => localStorage.getItem('refresh_token');
const apiClient = axios.create({
    baseURL: 'https://vcyrmobapp.elb.cisinlive.com', // Replace with your actual base URL
});

apiClient.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);
apiClient.interceptors.response.use(
    (response) => response,async(error,dispatch)=>{
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = getRefreshToken();
            if (refreshToken) {
                try {
                    console.log('hitting refresh  token')
                    const response = await axios.post('https://vcyrmobapp.elb.cisinlive.com/api/auth/refresh_token', { requestToken: refreshToken });
                    console.log(response)
                    const { access_token } = response.data.data;
                    localStorage.setItem('access_token', access_token);
                    apiClient.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
                    originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
                    return apiClient(originalRequest);
                } catch (refreshError) {
                    // console.log('Refresh token is not valid!');
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('user');
                    dispatch(logout())
                    window.location.href = '/'
                    return Promise.reject(refreshError);
                }
            } else {
                console.log('No refresh token available!');
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('user');
                dispatch(logout())
                window.location.href = '/'
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
)

export const createApiAction = (url, method, data = null,navigate) => async (dispatch) => {
    
    try {
        let response;

        switch (method) {
            case 'GET':
                response = await apiClient.get(url);
                break;
            case 'POST':
                response = await apiClient.post(url, data);
                break;
            case 'PUT':
                response = await apiClient.put(url, data);
                break;
            case 'DELETE':
                response = await apiClient.delete(url);
                break;
            default:
                throw new Error(`Unsupported request method: ${method}`);
        }
            await dispatch(fetchSuccess(response.data));
    } catch (error) {
        const errorCode=error.response.data.code
        // console.log(errorCode)
        if(errorCode===403)
        {
            console.log("invalid token")
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('user');
            dispatch(logout())
            window.location.href = '/'
        }
        await dispatch(fetchError(error.response.data));
        throw Error(errorCode)
    }
};
