
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Avatar, Typography, IconButton, Collapse, useTheme } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from '../theme';
import { formatDistanceToNow } from 'date-fns';

export const Comment = ({ comment }) => {
  
    const [open, setOpen] = React.useState(false);
    const theme=useTheme();
    const colors=tokens(theme.palette.mode);
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' }, 
          margin: 1, 
          width: '97%', 
          flex:'1 1 auto',
          borderRadius: '10px',
            backgroundColor: colors.primary[400],
          padding:'8px'
        }}
      >
        <Avatar alt={comment.user.username} src={comment.user.profile_image} sx={{ width: 40, height: 40 }} />
        <Box sx={{ marginLeft: { sm: 1 }, flex: 1 }}>
        {/* name and username */}
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              width: '100%', 
              maxWidth: '600px',
              alignItems:'center',
              gap:'4px'
              }}>
            <Box sx={{backgroundColor:`${colors.grey[100]}`,borderRadius:'12px'}}>
              <Typography variant='h6' sx={{ paddingLeft:'6px', paddingRight:'6px',fontWeight: 600, color: colors.primary[200]}}>
                @{comment.user.username}
              </Typography>
            </Box>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {comment.user.name}
          </Typography>
          <Typography variant="caption" sx={{fontSize:'0.6rem'}}>
            {formatDistanceToNow(comment.createdAt)}{'\u00A0'}ago
          </Typography>
          </Box>
          {/* comment */}
          <Typography variant="body1" sx={{ marginLeft: 1,marginTop:1,fontWeight:500}}>{comment.comment}</Typography>
          {comment.replies.length>0&&( <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>)}
          
          {/* Replies */}
          <Collapse in={open} timeout="auto" unmountOnExit>
            {comment.replies && comment.replies.map((reply) => (
              <Box 
                key={reply.id} 
                sx={{ 
                  display: 'flex', 
                  flexDirection: { xs: 'column', sm: 'row' }, 
                  marginTop: 1, 
                  marginLeft: { sm: 4 }, 
                  width: '100%', 
                  maxWidth: '600px',
                  // backgroundColor:`${colors.primary[100]}`
                }}
              >
                <Avatar alt={reply.user.name} src={reply.user.profile_image} sx={{ width: 30, height: 30 }} />
                <Box sx={{ marginLeft: { sm: 2 }, flex: 1 }}>
                <Box 
                    sx={{ 
                      display: 'flex', 
                      flexDirection: { xs: 'column', sm: 'row' },
                      width: '100%', 
                      maxWidth: '600px',
                      alignItems:'center',  
                      gap:'4px'
                      }}>
                    <Box sx={{backgroundColor:`${colors.grey[100]}`,borderRadius:'12px'}}>
                      <Typography variant='h6' sx={{ paddingLeft:'6px', paddingRight:'6px',fontWeight: 600, color: colors.primary[200]}}>
                        @{reply.user.username}
                      </Typography>
                    </Box>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    {reply.user.name}
                  </Typography>
                  <Typography variant="caption" sx={{fontSize:'0.6rem'}}>
                  {formatDistanceToNow(reply.createdAt)}{'\u00A0'}ago
                </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ marginLeft: 1,marginTop:1,fontWeight:500}} >{reply.comment}</Typography>
                </Box>
              </Box>
            ))}
          </Collapse>
        </Box>
      </Box>
    );
  };
  
  Comment.propTypes = {
    comment: PropTypes.shape({
      id: PropTypes.number.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        profile_image: PropTypes.string.isRequired,
      }).isRequired,
      text: PropTypes.string.isRequired,
      replies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          user: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string.isRequired,
          }).isRequired,
          text: PropTypes.string.isRequired,
        })
      ),
    }).isRequired,
  };
  