import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,  
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Popover,
  useMediaQuery,
  Typography,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {
  useCallback,
  useState,
  useEffect,
} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { createApiAction } from "../../redux/apiServices";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InfoIcon from '@mui/icons-material/Info';
import {useNavigate } from "react-router-dom";
import { MenuItem } from "react-pro-sidebar";
import EditIcon from '@mui/icons-material/Edit';

const Team = () => {
  const theme = useTheme();
  const colors = tokens(
    theme.palette.mode
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('1320'));;
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const [validateError,setValidateError]=useState(
    {name:"",email:"",password:"",founder:""})
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.api);
  const formData=new  FormData();
  const founder=[{"id":1,name:'YES',value:"true"},{"id":2,name:"NO",value:"false"}]
  const MenuProps = {
    PaperProps: {
      sx: {
        backgroundColor: `${colors.primary[700]}`,
        '& .MuiMenuItem-root': {
          padding: '10px 20px',  
          backgroundColor: `${colors.primary[700]}`,
          '&:hover': {
            backgroundColor: `${colors.primary[100]}`, 
          },
        },
      },
    },
  };
  const navigate=useNavigate()
  const validateUser=(user)=>{
    let valid=true;
    let newError={name:"",password:"",email:"",founder:""}
    if (!user.name) {
      newError.name = "Name is required";
      valid = false;
    }

    if (!user.email) {
      newError.email = "Email is required";
      valid = false;
    } else {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailPattern.test(user.email)) {
        newError.email = "Invalid email format";
        valid = false;
      }
    }

    if (!user.password) {
      newError.password = "Password is required";
      valid = false;
    }
    if (!user.founder) {
      newError.founder = "Please Select the value";
      valid = false;
    }

    setValidateError(newError)
    return valid; 
  }
  const getUser=useCallback(async()=>{
    await dispatch(createApiAction("/api/admin/get_user_list","GET"))
  },[dispatch])
  useEffect(() => {
    getUser();
  },[getUser]);

  const handleEdit = async (user) => {
    setSelectedUser(user);
    setNewUser({
      name: user.name,
      email: user.email,  
      password: "", 
    });
    setEditOpen(true);
  };

  const handleDelete = async () => {

    try {
        await dispatch(
        createApiAction(
          `/api/admin/delete_user`,
          "POST",
          {
            user_id:selectedUser.id
          }
        )
      );
      await getUser();
      setDeleteOpen(false);
      setSelectedUser(null);
      setAnchorEl(false)
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddUser = async () => {
    if(validateUser(newUser)){try {
      await dispatch(
        createApiAction(
          "/api/admin/add_user",
          "POST",
          {
            name: newUser.name, 
            email: newUser.email,
            password: newUser.password,
            is_founder:newUser.founder
          }
        )
      );
      await getUser();
      setOpen(false);
      setNewUser({
        name: "",
        email: "",
        password: "",
      });
    } catch (error) {
      setValidateError(prev=>({...prev,email:error.message}))
    }}
  };

  const handleUpdateUser = async () => {  
    if (validateUser(newUser)) {try{
        formData.append('user_id',selectedUser.id)
        formData.append('name', newUser.name);
        formData.append('password', newUser.password);
        formData.append('is_founder',newUser.founder)
        formData.append('profile_image',newUser.file);
        // for (let [key, value] of formData.entries()) {
        //   console.log(`${key}: ${value}`);
        // }
      await dispatch(createApiAction('/api/admin/edit_user','POST',formData))
      await getUser();
        setEditOpen(false);
        setNewUser({
          name: "", 
          email: "",
          password: "",
        });
        setAnchorEl(false)
    } catch (error) {
      console.error(
        "Error updating user:",
        error
      );
    }}
  };

  const handleInfo=async(user)=>{
    navigate(`${user.id}`)
  }
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //search functionality
  const searchtag=useSelector((state)=>state.api.search)
  const filteredCategories = Array.isArray(data?.data)
  ? data.data.filter(category =>
      category?.name?.toLowerCase().includes(searchtag.toLowerCase())
    )
  : [];

  const columns = [
    { field: "id", flex:1,headerName: "ID", minWidth: 120, maxWidth:80},
    { 
      field: "profile_image",
      headerName: "Image",
      cellClassName:
        "name-column--cell",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        const fallbackImage = "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg";

      const handleError = (event) => {
        event.target.src = fallbackImage;
      };
        return (
        <Box
          display="flex"
          gap={2}
          alignItems="center"
          height="100%"
          justifyContent="left"
          
        >
          <Box
            component="img"
            src={params.value}
            alt="null"
            onError={handleError}
            sx={{
              border:`1px solid ${colors.primary[200]}`,
              width: 40,
              height: 40,
              borderRadius: "50%",
              objectFit: "contain",
            }}
          />
        </Box>
      )},
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      cellClassName:
        "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
      cellClassName:
        "name-column--cell",
    },
    {
      field: "is_founder",
      headerName: "Founder",
      flex: 1,
      minWidth: 100,
      maxWidth:100,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Box display="flex"
        flex="1 1 auto"
        gap={2}
        alignItems="center"
        height="100%"
      
        alignContent="center"
        justifyContent={isMobile ? "center" : "flex-start"}>
          <Box
          sx={{
            display:"flex",
            flex:"1 1 auto",
            border:'1px solid',
            
            borderColor: params.value ? 'green' : 'red',
            color: 'white',
            borderRadius: 2,
            alignItems:"center",
            justifyContent:"center",
            textAlign: 'center',
            padding: '5px 10px',
          }}
        >
          <Typography variant="body2" fontWeight="bold" color={params.value ? 'green' : 'red'}>
          {params.value ? 'Yes' : 'No'}
        </Typography>
        </Box>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Box
          display="flex"
          flex="1 1 auto"
          gap={2}
          alignItems="center"
          height="100%"
          justifyContent={isMobile ? "center" : "flex-start"}
        >
          {isMobile ? (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(event)=>{
                  handleActionsClick(event)
                  setSelectedUser(params.row)
                }
                }
              >
                Actions
              </Button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  padding: '10px',
                  backgroundColor: colors.primary[200],
                  flexWrap: 'wrap',
                }}>
                <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => handleEdit(selectedUser)}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setDeleteOpen(true)}}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<InfoIcon />}
                onClick={() => handleInfo(selectedUser  )}
              >
                Info
              </Button>
                </Box>

              </Popover>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => handleEdit(params.row)}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setSelectedUser(params.row)
                  setDeleteOpen(true)}
                }
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<InfoIcon />}
                onClick={() => handleInfo(params.row)}
              >
                Info
              </Button>
            </>
          )}
        </Box>
      ),
    }
  ]; 
  return (
    <Box m="0px 20px 20px 20px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
            px: 2, // Padding for responsiveness
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              alignItems: 'center',
            },
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              '@media (max-width: 600px)': {
                justifyContent: 'center',
                mb: 1, // Margin bottom for spacing in smaller screens
              },
            }}
          >
            <Header
                title="Managing User"
              />
          </Box>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={() => {
              setOpen(true)
              }}
            sx={{
              '@media (max-width: 600px)': {
                width: '100%',
              },
            }}
          >
            Add User
          </Button>
        </Box>
      
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            maxWidth: "100%",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },  
          "& .name-column--cell": {
            color:
              colors.grey[100],
          },
          "& .MuiDataGrid-columnHeaders":
            {
              backgroundColor:
                colors.redAccent[700],
              borderBottom: "none",
            },
          "& .MuiDataGrid-virtualScroller":
            {
              backgroundColor:
                colors.primary[400],
                maxHeight: "100%",
            },
          "& .MuiDataGrid-footerContainer":
            {
              borderTop: "none",
              backgroundColor:
                colors.primary[500],
            },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent":{
            fontFamily:"Sharnay Heavy",
          },
          '& .MuiDataGrid-scrollbar': {
            width:"7px"
          },
        }}
      >
        <DataGrid
        sx={{
          overflow:'auto'
        }}
          columns={columns}
        rows={filteredCategories||[]}

        />
      </Box>
        {/* dialog for add user */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor:
              colors.primary[600],
          },
        }}
        open={open}
        onClose={() => {
          setOpen(false)
          setSelectedUser(false)
          setValidateError(false) 
        }}
      >
        <Box
          padding={"20px 0px 0px 25px"}
          color={
            colors.greenAccent[200]
          }
        >
          Add user
        </Box>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            sx={{
              "& .MuiOutlinedInput-root":
                {
                  color: "#fff",
                  "& .MuiOutlinedInput-notchedOutline":
                    {
                      borderWidth:
                        "1px",
                    },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor:
                          colors
                            .greenAccent[200],
                        borderWidth:
                          "2px",
                      },
                  },
                  "& .MuiInputLabel-outlined":
                    {
                      color: "#fff",
                      fontWeight:
                        "bold",
                      "&.Mui-focused": {
                        color: "#fff",
                        fontWeight:
                          "bold",
                      },
                    },
                },
            }}
            label="Name"
            color="secondary"
            type="text"
            fullWidth
            variant="outlined"
            value={newUser.name}
            onChange={(e) =>{
              setNewUser({
                ...newUser,
                name: e.target.value,
              })
            }
            }
            onClick={()=>{
              setValidateError(prevState=>({...prevState,name:false}))
            }}
            error={!!validateError.name}
              helperText={validateError.name}
          />
          <TextField
            margin="dense"
            label="Email"
            sx={{
              "& .MuiOutlinedInput-root":
                {
                  color: "#fff",
                  "& .MuiOutlinedInput-notchedOutline":
                    {
                      borderWidth:
                        "1px",
                    },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor:
                          colors
                            .greenAccent[200],
                        borderWidth:
                          "2px",
                      },
                  },
                  "& .MuiInputLabel-outlined":
                    {
                      color: "#fff",
                      fontWeight:
                        "bold",
                      "&.Mui-focused": {
                        color: "#fff",
                        fontWeight:
                          "bold",
                      },
                    },
                },
            }}
            type="email"
            color="secondary"
            fullWidth
            variant="outlined"
            value={newUser.email}
            onChange={(e) =>{
              setNewUser({
                ...newUser,
                email: e.target.value,
              })
            }
            }
            onClick={()=>{
              setValidateError(prevState=>({...prevState,email:false}))
            }}
            error={!!validateError.email}
              helperText={validateError.email}
          />
          <TextField
            margin="dense"
            label="Password"
            sx={{
              "& .MuiOutlinedInput-root":
                {
                  color: "#fff",
                  "& .MuiOutlinedInput-notchedOutline":
                    {
                      borderWidth:
                        "1px",
                    },
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor:
                          colors
                            .greenAccent[200],
                        borderWidth:
                          "2px",
                      },
                  },
                  "& .MuiInputLabel-outlined":
                    {
                      color: "#fff",
                      fontWeight:
                        "bold",
                      "&.Mui-focused": {
                        color: "#fff",
                        fontWeight:
                          "bold",
                      },
                    },
                },
            }}
            type="text"
            color="secondary"
            fullWidth
            variant="outlined"
            value={newUser.password}
            onChange={(e) =>
              setNewUser({
                ...newUser,
                password: e.target.value,
              })
            }
            onClick={()=>{
              setValidateError(prevState=>({...prevState,password:false}))
            }}
            error={!!validateError.password}
              helperText={validateError.password}
          />
            <FormControl
          fullWidth
          margin="dense"
          error={!!validateError.founder}
          sx={{
            paddingBottom: '4px',
            "& .MuiOutlinedInput-root":
            {
              color: "#fff",
              "& .MuiOutlinedInput-notchedOutline":
                {
                  borderWidth:
                    "1px",
                },
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor:
                      colors
                        .greenAccent[200],
                    borderWidth:
                      "2px",
                  },
              },
              "& .MuiInputLabel-outlined":
                {
                  color: "#fff",
                  fontWeight:
                    "bold",
                  "&.Mui-focused": {
                    color: "#fff !important",
                    fontWeight:
                      "bold",
                  },
                },
            },
            '& .MuiList-root-MuiMenu-list':{
                backgroundColor: colors.greenAccent[900],
              },
            '& .MuiSelect-icon': {
              fill: `${colors.primary[700]}`,
            },
          }}
        >
          <InputLabel>Founder</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            MenuProps={MenuProps}
            onClick={() => {
              setValidateError((prevstate) => ({ ...prevstate, founder: '' }));
            }}
            onChange={(e) =>
              setNewUser({
                ...newUser,
                founder: e.target.value,
              })
            }
            label="Enjoyed"
            sx={{
              '& .MuiInputBase-input': {
                color: colors.primary[100],
              },
            }}
          >
            {founder.map((item)=>(
                  <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                ))}
          </Select>
          {/* {errors.enjoyed && <FormHelperText>{errors.enjoyed}</FormHelperText>} */}
        </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>{
              setAnchorEl(false)
              setOpen(false)
              setSelectedUser(null)
              
            }}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddUser}
            variant="contained"
            color="secondary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
        {/* dialog for edit user */}
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              backgroundColor:
                colors.primary[600],
            },
          }}
          open={editOpen}
          onClose={() => {
            setEditOpen(false)
            setSelectedUser(false)
            setNewUser(false)
          }}
        >
          <Box
            padding={"20px 0px 0px 25px"}
            color={
              colors.greenAccent[200]
            }
          >
            Edit user
          </Box>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              sx={{
                "& .MuiOutlinedInput-root":
                  {
                    color: "#fff",
                    "& .MuiOutlinedInput-notchedOutline":
                      {
                        borderWidth:
                          "1px",
                      },
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor:
                            colors
                              .greenAccent[200],
                          borderWidth:
                            "2px",
                        },
                    },
                    "& .MuiInputLabel-outlined":
                      {
                        color: "#fff",
                        fontWeight:
                          "bold",
                        "&.Mui-focused": {
                          color: "#fff",
                          fontWeight:
                            "bold",
                        },
                      },
                  },
              }}
              label="Name"
              color="secondary"
              type="text"
              fullWidth
              variant="outlined"
              value={newUser.name}
              onChange={(e) =>
                setNewUser({
                  ...newUser, 
                  name: e.target.value,
                })
              }
              onClick={()=>(setValidateError(prev=>({
                ...prev,name:false
              })))}
              error={!!validateError.name}
              helperText={validateError.name}
            />
          <Box display="flex" alignItems="center">
            <FormControl
              margin="dense"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  pointerEvents: "none",
                  color: "#fff",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "1px",
                  },
                  "&.Mui-focused": {                   
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: colors.greenAccent[200],
                      borderWidth: "2px",
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    color: "#fff",
                    fontWeight: "bold",
                    "&.Mui-focused": {
                      color: "#fff",
                      fontWeight: "bold",
                    },
                  },
                },
              }}
            >
              <InputLabel
                htmlFor="profile-image"
                sx={{
                  color: "#fff",
                  "&.Mui-focused": {
                    color: colors.greenAccent[200],
                    },
                }}  
              >
                Profile Image
              </InputLabel>
              <OutlinedInput
                id="profile-image"
                type="null"
                value={newUser.file ? newUser.file.name : ''}
                onChange={(e) =>
                  setNewUser({
                    ...newUser,
                    file: e.target.value,
                  })
                }
                
                label="Profile Image"
              />
            </FormControl>
            <InputAdornment position="end">
                    <IconButton
                      component="label"
                      sx={{
                        color: "#fff",
                        backgroundColor: colors.primary[500],
                      }}
                    >
                      <UploadFileIcon />
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            setNewUser({
                              ...newUser,
                              file: file,
                            });
                          }
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
            </Box> 
            <TextField
              margin="dense"
              label="Password"
              sx={{
                "& .MuiOutlinedInput-root":
                  {
                    color: "#fff",
                    "& .MuiOutlinedInput-notchedOutline":
                      {
                        borderWidth:
                          "1px",
                      },
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor:
                            colors
                              .greenAccent[200],
                          borderWidth:
                            "2px",
                        },
                    },
                    "& .MuiInputLabel-outlined":
                      {
                        color: "#fff",
                        fontWeight:
                          "bold",
                        "&.Mui-focused": {
                          color: "#fff",
                          fontWeight:
                            "bold",
                        },
                      },
                  },
              }}
              type="text"
              color="secondary"
              fullWidth
              variant="outlined"
              value={newUser.password}
              onChange={(e) =>
                setNewUser({
                  ...newUser,
                  password: e.target.value,
                })
              }
              onClick={()=>setValidateError(prev=>({...prev,password:false}))}
              error={!!validateError.password}
              helperText={validateError.password}
            />
            <FormControl
          fullWidth
          margin="dense"
          error={!!validateError.founder}
          sx={{
            paddingBottom: '4px',
            "& .MuiOutlinedInput-root":
            {
              color: "#fff",
              "& .MuiOutlinedInput-notchedOutline":
                {
                  borderWidth:
                    "1px",
                },
              "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor:
                      colors
                        .greenAccent[200],
                    borderWidth:
                      "2px",
                  },
              },
              "& .MuiInputLabel-outlined":
                {
                  color: "#fff",
                  fontWeight:
                    "bold",
                  "&.Mui-focused": {
                    color: "#fff !important",
                    fontWeight:
                      "bold",
                  },
                },
            },
            '& .MuiList-root-MuiMenu-list':{
              padding:"4px",
                backgroundColor: colors.greenAccent[900],
              },
            '& .MuiSelect-icon': {
              fill: `${colors.primary[700]}`,
            },
          }}
        >
          <InputLabel
          sx={{
            color: "#fff",
            "&.Mui-focused": {
              color: colors.greenAccent[200],
              },
          }}  >Founder</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            MenuProps={MenuProps}
            onClick={() => {
              setValidateError((prevstate) => ({ ...prevstate, founder: '' }));
            }}
            onChange={(e) =>
              setNewUser({
                ...newUser,
                founder: e.target.value,
              })
            }
            label="Enjoyed"
            sx={{
              '& .MuiInputBase-input': {
                color: colors.primary[100],
              },
            }}
          >
            <MenuItem  value={"true"}>Yes</MenuItem>
            <MenuItem  value={"false"}>No</MenuItem>
          </Select>
          {/* {errors.enjoyed && <FormHelperText>{errors.enjoyed}</FormHelperText>} */}
        </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>{
                setAnchorEl(false)
                setEditOpen(false)
                setSelectedUser(false)
                setNewUser(false)
                
              }}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpdateUser}
              variant="contained"
              color="secondary"
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        {/* dialog for delete user */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor:
              colors.primary[600],
          },
        }}
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
          onClick={() =>{setAnchorEl(false)
              setDeleteOpen(false)}}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            onClick={handleDelete}
            variant="contained"
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Team;
